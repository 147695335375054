import React, { useCallback, useEffect, useState } from "react";
import makeApiCall from "../callApi";
import ReactQuill from "react-quill";
import crossblack_icon from "../../assets/images/png/blackcross_icon.png";
import edit_icon from "../../assets/images/png/edit_icon.png";
import delete_icon from "../../assets/images/png/delete_icon.png";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import MultiySelectComponent from "./MultiySelectComponent";

// import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";

const CourseTab = () => {
  const [loading, setLoading] = useState(false);
  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const [addCourseModal, setAddCourseModal] = useState(false);
  const [editCourseId, setEditCourseId] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const [languageValue, setLanguageValue] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [trainerList, setTrainerList] = useState([]);
  const [videoFild, setVideoFild] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const [courseData, setCourseData] = useState({
    title: "",
    image: "",
    type: "",
    validity: "",
    price: "",
    discount: "",
    file: null,
    timing: "",
    trainer: "",
    shortDescription: "",
    description: "",
  });
  const [newCourseData, setNewCourseData] = useState({ title: "" });
  const [value, setValue] = useState("");
  const [newValue, setNewValue] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // Handler for input field changes
  const handleInputChange4 = (e) => {
    setInputValue(e.target.value);
  };

  // Handler for button click to add the input value to the array
  const addToArray = (type) => {
    if (type === 1) {
      if (inputValue.trim() !== "") {
        setDataArray((prevArray) => [...prevArray, inputValue]); // Add the input value to the array
        setInputValue("");
      }
    }
    if (type === 2) {
      if (languageValue.trim() !== "") {
        setLanguages((prevArray) => [...prevArray, languageValue]); // Add the input value to the array
        setLanguageValue("");
      }
    }
  };

  useEffect(() => {
    setNewCourseData({
      id: currentData?._id,
      title: currentData?.title,
      image: "",
      type: currentData?.type,
      validity: currentData?.validity,
      price: currentData?.price,
      discount: currentData?.discount,
      timing: currentData?.timing,
      location: currentData?.location,
      trainer: currentData?.trainer_id,
      sort_description: currentData?.sort_description,
      description: currentData?.description,
    });
    setNewValue(currentData?.description);
  }, [currentData]);

  useEffect(() => {
    console.log(courseData);
  }, [courseData]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setCourseData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
      setCourseData((prevData) => ({ ...prevData, file: e.target.files[0] }));
    } else {
      setCourseData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  function handleInputChange2(e) {
    console.log(e);
    const { name, value, files } = e.target;
    if (files) {
      setNewCourseData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setNewCourseData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    console.log(newCourseData);
  }

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video", "button"],
      ["clean"],
      ["code-block"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  async function createCourse(e) {
    console.log(value);
    e.preventDefault();
    setLoading(true);

    const formdata = new FormData();
    formdata.append("title", courseData.title);
    formdata.append("description", value);
    formdata.append("sort_description", courseData.shortDescription);
    formdata.append("type", courseData.type);
    formdata.append("image", courseData.image);
    formdata.append("timing", courseData.timing);
    formdata.append("discount", courseData.discount);
    formdata.append("price", courseData.price);
    formdata.append("validity", courseData.validity);
    formdata.append("location", courseData.location);
    formdata.append("trainer_id", courseData.trainer);
    formdata.append("video", courseData.file);
    languages.forEach((lang, index) => {
      formdata.append(`technology[]`, lang);
    });

    dataArray.forEach((feature, index) => {
      formdata.append(`features[]`, feature);
    });

    selectedValues.forEach((value, index) => {
      formdata.append(`category[]`, value);
    });

    try {
      const response = await makeApiCall(
        formdata,
        "post",
        "admin/updateCourse",
        createCourseCallBack,
        true
      );
    } catch (error) {
      console.error("Error creating course:", error);
    } finally {
      setLoading(false);
    }
  }

  async function updateCourse(e) {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("id", newCourseData.id);
    formdata.append("title", newCourseData.title);
    formdata.append("description", newValue);
    formdata.append("sort_description", newCourseData.sort_description);
    formdata.append("type", newCourseData.type);
    formdata.append("timing", newCourseData.timing);
    formdata.append("image", newCourseData.image);
    formdata.append("discount", newCourseData.discount);
    formdata.append("price", newCourseData.price);
    formdata.append("location", newCourseData.location);
    formdata.append("validity", newCourseData.validity);
    formdata.append("trainer_id", newCourseData.trainer);

    await makeApiCall(
      formdata,
      "post",
      "admin/updateCourse",
      updateeCourseCallBack,
      true
    );
  }

  const createCourseCallBack = useCallback(async (res) => {
    if (res.data.status == "success") {
      setAddCourseModal(false);
      getCourse();
      toast(res?.data?.message);
    } else {
      toast(res?.data?.message);
    }
  });

  const updateeCourseCallBack = useCallback(async (res) => {
    if (res.data.status == "success") {
      setFreeTrialModalVisible(false);
      getCourse();
    }
  });

  const [coursesList, SetCoursesList] = useState([]);
  async function getCourse() {
    await makeApiCall("", "get", "admin/courseList", coursesListCallBack);
  }

  const coursesListCallBack = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      SetCoursesList(response.data.data);
    } else {
      console.log("error");
    }
  });

  const coursesDeleteCallBack = useCallback(
    (response) => {
      console.log(response);
      if (response.status === 200) {
        console.log("Course deleted successfully");
        getCourse();
      } else {
        console.error("Error deleting course");
      }
    },
    [getCourse]
  );

  async function deleteCourse(id) {
    await makeApiCall(
      "",
      "get",
      `admin/deleteCourse/${id}`,
      coursesDeleteCallBack
    );
  }
  useEffect(() => {
    getCourse();
  }, []);

  // Trainers API-----------------

  useEffect(() => {
    makeApiCall("", "get", "admin/trainersList", trainersListCallBack);
  }, []);
  const trainersListCallBack = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      setTrainerList(response.data.data);
      console.log(response);
    } else {
      console.log("error");
    }
  });

  // -------------------
  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  const handleRemove = (index, type) => {
    if (type === 1) {
      const data = [...dataArray];
      data.splice(index, 1);
      setDataArray(data);
    }
    if (type === 2) {
      const data = [...languages];
      data.splice(index, 1);
      setLanguages(data);
    }
  };

  // ------------------------------------------
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    makeApiCall("", "get", "admin/contentList", contentListCallBack);
  }, []);
  const contentListCallBack = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      setContentList(response.data.data);
      console.log(response);
    } else {
      console.log("error");
    }
  });

  const handlePlayClick = (video) => {
    setSelectedFile(video);
    setShowVideoModal(true);
  };

  return (
    <div>
      <>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className=" fs_37 fw-semibold ff_heebo">Courses</h2>
          </div>
          <div className="border mb-4 mt-3"></div>
        </div>
        {!addCourseModal ? (
          <>
            <div className="pe-4 mb-4">
              <button
                onClick={() => setAddCourseModal(true)}
                className=" bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold"
              >
                ADD NEW COURSE
              </button>
            </div>
            <div
              className="card rounded-3"
              style={{ maxWidth: "1300px", overflowX: "auto" }}
            >
              <table className="w-100">
                <tr>
                  <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                    Image
                  </th>
                  <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                    Title
                  </th>
                  <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                    Type
                  </th>
                  <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                    Price
                  </th>

                  <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                    Discount
                  </th>
                  <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                    Validity
                  </th>
                  <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                    Video
                  </th>
                  <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                    Action
                  </th>
                </tr>
                {coursesList.length < 1 ? (
                  <div className="text-start ps-3 w-100 py-4">
                    <p>No data found</p>
                  </div>
                ) : (
                  <>
                    {coursesList.map((obj, i) => (
                      <tr key={i}>
                        <td className="px-3 py-3">
                          <img
                            width={70}
                            src={`https://api.koduapp.com/public/images/${obj.image}`}
                            alt=""
                          />
                        </td>
                        <td className="px-3 py-3">{obj.title}</td>
                        <td className="px-3 py-3">{obj.type}</td>
                        <td className="px-3 py-3">{obj.price}</td>
                        <td className="px-3 py-3">
                          {obj?.discount ? (
                            <>{obj?.discount}</>
                          ) : (
                            <>
                              <>NA</>
                            </>
                          )}
                        </td>
                        <td className="px-3 py-3">{obj.validity} Months</td>
                        <td className="px-3 py-3">
                          {obj.video ? (
                            <>
                              <button
                                onClick={() => handlePlayClick(obj.video)}
                                className=" bg-success py-2 px-4 border-0 ff_heebo text-white fw-bold"
                              >
                                Play Video
                              </button>
                            </>
                          ) : (
                            <>Na</>
                          )}
                        </td>
                        <td className="px-3 py-3">
                          <div className="d-flex gap-3 bg-white">
                            <img
                              onClick={() => {
                                toggleFreeTrialModal();
                                setCurrentData(obj);
                              }}
                              width={20}
                              className="cursor_pointer"
                              src={edit_icon}
                              alt=""
                            />
                            <img
                              onClick={() => deleteCourse(obj._id)}
                              width={20}
                              className="cursor_pointer"
                              src={delete_icon}
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </table>
            </div>
          </>
        ) : (
          <>
            {" "}
            {loading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <div className="card rounded-0 px-5 py-4 position-relative">
                <div className="position-absolute top-0 end-0">
                  <img
                    onClick={() => setAddCourseModal(false)}
                    width={20}
                    className="me-3 mt-3 cursor_pointer"
                    src={crossblack_icon}
                    alt="Close"
                  />
                </div>
                <div>
                  <form onSubmit={createCourse}>
                    <div className="d-flex w-100 flex-column col-3 mt-4">
                      <lable>Title</lable>
                      <input
                        required
                        className="custom_admin_input mt-2  px-3 py-2 w-100"
                        type="text"
                        name="title"
                        value={courseData.title}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="row">
                      <div className="d-flex col-6 flex-column col-3 mt-4">
                        <lable>Type</lable>
                        {/* <input
                        required
                        className="custom_admin_input mt-2  px-3 py-2 w-100"
                        type="text"
                        name="type"
                        value={courseData.type}
                        onChange={handleInputChange}
                      /> */}
                        <select
                          className="py-2 px-3 mt-2"
                          value={courseData.type}
                          onChange={handleInputChange}
                          name="type"
                          id=""
                        >
                          <option value="Select Type">Select Type</option>
                          <option value="Online">Online</option>
                          <option value="Offline">Offline</option>
                        </select>
                      </div>

                      <div className="d-flex col-6 flex-column col-3 mt-4">
                        <lable>Validity</lable>
                        <input
                          required
                          className="custom_admin_input mt-2  px-3 py-2 w-100"
                          type="number"
                          name="validity"
                          value={courseData.validity}
                          onChange={handleInputChange}
                        />
                        {/* <select
                          className="py-2 px-3 mt-2"
                          value={courseData.validity}
                          onChange={handleInputChange}
                          name="validity"
                          id=""
                        >
                          <option value="Select Validity">
                            Select Validity
                          </option>
                          <option value="6 Months">3 Months</option>
                          <option value="6 Months">6 Months</option>
                          <option value="12 Months">12 Months</option>
                        </select> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex col-6 flex-column col-3 mt-4">
                        <lable>Price</lable>
                        <input
                          required
                          className="custom_admin_input mt-2  px-3 py-2 w-100"
                          type="number"
                          name="price"
                          value={courseData.price}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="d-flex col-6 flex-column col-3 mt-4">
                        <lable>Discount</lable>
                        <input
                          required
                          className="custom_admin_input mt-2  px-3 py-2 w-100"
                          type="number"
                          name="discount"
                          value={courseData.discount}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex flex-column col-6 col-3 mt-4">
                        <lable>Image</lable>
                        <input
                          required
                          className="custom_admin_input mt-2  px-3 py-2 w-100"
                          type="file"
                          name="image"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="d-flex col-6  flex-column col-3 mt-4">
                        <lable>Short Description</lable>
                        <input
                          required
                          className="custom_admin_input mt-2  px-3 py-2 w-100"
                          type="text"
                          name="shortDescription"
                          value={courseData.shortDescription}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex flex-column col-6 px-2 flex-column col-3 mt-4">
                        <lable>Loction</lable>
                        <select
                          className="py-2 px-3 "
                          value={courseData.location}
                          onChange={handleInputChange}
                          name="location"
                          id=""
                        >
                          <option value="Select Location">
                            Select Location
                          </option>
                          <option value="Hisar">Hisar</option>
                          <option value="Sirsa">Sirsa</option>
                          <option value="Rohtak">Rohtak</option>
                          <option value="Jaipur">Jaipur</option>
                        </select>
                      </div>
                      <div className="d-flex flex-column col-6 px-2 flex-column col-3 mt-3">
                        <label className="mb-2">Category</label>
                        <MultiySelectComponent
                          selectedValues={selectedValues}
                          setSelectedValues={setSelectedValues}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex flex-column col-6 px-2 flex-column col-3 mt-4">
                        <lable>Trainers</lable>
                        <select
                          className="py-2 px-3 "
                          value={courseData.trainer}
                          onChange={handleInputChange}
                          name="trainer"
                          id=""
                        >
                          <option value="Select Trainers">
                            Select Trainers
                          </option>
                          {trainerList.map((obj, i) => {
                            return (
                              <>
                                <option key={i} value={obj._id}>
                                  {obj.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>

                      <div className="d-flex col-6 flex-column col-3 mt-3">
                        <lable>Date/Time</lable>
                        <input
                          required
                          className="custom_admin_input mt-2  px-3 py-2 w-100"
                          type="date"
                          name="timing"
                          value={courseData.timing}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    {courseData.type === "Offline" ? (
                      <Form.Group className=" mt-4">
                        <Form.Label>Upload Demo Video</Form.Label>
                        <Form.Control
                          type="file"
                          name="file"
                          accept="video/*,audio/mp3"
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Text className="text-muted mt-1">
                          Please upload a video (mp4, mkv, etc.) or an audio
                          file (mp3).
                        </Form.Text>
                      </Form.Group>
                    ) : null}
                    <div className="row">
                      <div className="col-lg-6 p-3">
                        <div className="d-flex mt-4 flex-column">
                          <lable className="mb-2">Features</lable>
                          <div className="d-flex gap-3 align-items-center">
                            {" "}
                            <input
                              type="text"
                              className="w-100 py-2 px-3 custom_admin_input"
                              value={inputValue}
                              onChange={handleInputChange4}
                            />
                            <button
                              className="px-3 py-2 border-0 text-nowrap"
                              type="button"
                              onClick={() => addToArray(1)}
                            >
                              Add to Features
                            </button>
                          </div>
                        </div>

                        <h5 className="mt-3">Features Content:</h5>
                        <div className="col-md-12 px-3">
                          <div
                            style={{ height: "150px" }}
                            className="row overflow-y-auto"
                          >
                            <ul className="ps-0 d-flex gap-2 flex-wrap align-items-baseline  ">
                              {dataArray?.map((item, index) => (
                                <span
                                  className="rounded-5 d-flex gap-2 justify-content-between align-items-center border-1 border p-2"
                                  key={index}
                                >
                                  <p className="mb-0">{item}</p>
                                  <img
                                    className=" cursor_pointer"
                                    width={20}
                                    src={crossblack_icon}
                                    alt=""
                                    onClick={() => handleRemove(index, 1)}
                                  />
                                </span>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 p-3">
                        <div className="d-flex mt-4 flex-column">
                          <lable className="mb-2">Technology</lable>
                          <div className="d-flex gap-3 align-items-center">
                            <input
                              type="text"
                              className="w-100 py-2 px-3 custom_admin_input"
                              value={languageValue}
                              onChange={(e) => {
                                setLanguageValue(e.target.value);
                              }}
                            />
                            <button
                              className="px-3 py-2 border-0 text-nowrap"
                              type="button"
                              onClick={() => addToArray(2)}
                            >
                              Add to Technology
                            </button>
                          </div>
                        </div>

                        <h5 className="mt-3">Technology Content:</h5>
                        <div className="col-md-12 px-3">
                          <div
                            style={{ height: "150px" }}
                            className="row overflow-y-auto"
                          >
                            <ul className="ps-0 d-flex gap-2 flex-wrap align-items-baseline  ">
                              {languages?.map((item, index) => (
                                <span
                                  className="rounded-5 d-flex gap-2 justify-content-between align-items-center border-1 border p-2"
                                  key={index}
                                >
                                  <p className="mb-0">{item}</p>
                                  <img
                                    className=" cursor_pointer"
                                    width={20}
                                    src={crossblack_icon}
                                    alt=""
                                    onClick={() => handleRemove(index, 2)}
                                  />
                                </span>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex table_contentli w-100 flex-column col-3 mt-4">
                      <lable className="mb-2">Description</lable>
                      {/* <ReactQuill
                      theme="snow"
                      placeholder="Add Description"
                      modules={modules}
                      value={value}
                      onChange={setValue}
                    /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        value={value}
                        onChange={(e, ed) => {
                          const data = ed.getData();
                          setValue(data);
                        }}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                      />
                    </div>
                    <span>
                      <button
                        className="mt-5 border-0 bg_darkblue px-5 py-2 roundd-2 fs_16 ff_heebo fw-medium text-white"
                        type="submit"
                      >
                        Submit
                      </button>
                    </span>
                  </form>
                </div>
              </div>
            )}
          </>
        )}
      </>
      <Modal
        size="lg"
        show={freeTrialModalVisible}
        centered
        onHide={toggleFreeTrialModal}
      >
        <div
          style={{ width: "100%", margin: "0 auto" }}
          className="bg-white rounded-3 "
        >
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFreeTrialModal}
              width={20}
              className="me-2 mt-2 cursor_pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>

          <Modal.Body>
            <form className="mt-4" onSubmit={updateCourse}>
              <div className="d-flex w-100 flex-column col-3 mt-4">
                <lable>Title</lable>
                <input
                  className="custom_admin_input mt-2  px-3 py-2 w-100"
                  type="text"
                  name="title"
                  value={newCourseData?.title}
                  onChange={handleInputChange2}
                />
              </div>

              <div className="row">
                <div className="d-flex col-6 flex-column col-3 mt-4">
                  <lable>Type</lable>
                  {/* <input
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="text"
                    name="type"
                    value={newCourseData?.type}
                    onChange={handleInputChange2}
                  /> */}
                  <select
                    required
                    className="py-2 px-3"
                    value={newCourseData?.type}
                    onChange={handleInputChange2}
                    name="type"
                    id=""
                  >
                    <option value="Select Type">Select Type</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Ofline</option>
                  </select>
                </div>
                <div className="d-flex col-6 flex-column col-3 mt-4">
                  <lable>Validity</lable>
                  {/* <input
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="number"
                    name="validity"
                    value={newCourseData?.validity}
                    onChange={handleInputChange2}
                  /> */}
                  <select
                    required
                    className="py-2 px-3"
                    value={newCourseData?.validity}
                    onChange={handleInputChange2}
                    name="validity"
                    id=""
                  >
                    <option value="Select Validity">Select Validity</option>
                    <option value="6">3</option>
                    <option value="6">6</option>
                    <option value="12">12</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="d-flex col-6 flex-column col-3 mt-4">
                  <lable>Price</lable>
                  <input
                    required
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="number"
                    name="price"
                    value={newCourseData?.price}
                    onChange={handleInputChange2}
                  />
                </div>

                <div className="d-flex col-6 flex-column col-3 mt-4">
                  <lable>Discount</lable>
                  <input
                    required
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="number"
                    name="discount"
                    value={newCourseData?.discount}
                    onChange={handleInputChange2}
                  />
                </div>
              </div>
              <div className="row">
                <div className="d-flex col-6 flex-column col-3 mt-4">
                  <lable>Image</lable>
                  <input
                    required
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="file"
                    name="image"
                    onChange={handleInputChange2}
                  />
                </div>
                <div className="d-flex col-6  flex-column col-3 mt-4">
                  <lable>Short Description</lable>
                  <input
                    required
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="text"
                    name="sort_description"
                    value={newCourseData?.sort_description}
                    onChange={handleInputChange2}
                  />
                </div>
              </div>

              <div class="row">
                <div className="d-flex flex-column col-6 px-2 flex-column col-3 mt-4">
                  <lable>Loction</lable>
                  <select
                    required
                    className="py-2 px-3"
                    value={newCourseData.location}
                    onChange={handleInputChange2}
                    name="location"
                    id=""
                  >
                    <option value="Select Location">Select Location</option>
                    <option value="Hisar">Hisar</option>
                    <option value="Sirsa">Sirsa</option>
                    <option value="Rohtak">Rohtak</option>
                    <option value="Jaipur">Jaipur</option>
                  </select>
                </div>
                <div className="d-flex flex-column col-6 px-2 flex-column col-3 mt-4">
                  <lable>Trainers</lable>
                  <select
                    required
                    className="py-2 px-3 "
                    value={newCourseData.trainer_id}
                    onChange={handleInputChange2}
                    name="trainer_id"
                    id=""
                  >
                    <option value="Select Trainers">Select Trainers</option>
                    {trainerList.map((obj, i) => {
                      return (
                        <>
                          <option key={i} value={obj._id}>
                            {obj.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="d-flex col-6 flex-column col-3 mt-3">
                  <lable>Date/Time</lable>
                  <input
                    required
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="text"
                    name="timing"
                    value={newCourseData.timing}
                    onChange={handleInputChange2}
                  />
                </div>
              </div>

              <div className="d-flex w-100 table_contentli flex-column  mt-4">
                <lable className="mb-2">Description</lable>
                {/* <ReactQuill
                  theme="snow"
                  placeholder="Add Description"
                  modules={modules}
                  value={newValue}
                  onChange={setNewValue}
                /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={newValue}
                  onChange={(e, ed) => {
                    const data = ed.getData();
                    setNewValue(data);
                  }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                />
              </div>

              <span>
                <button
                  className="mt-5 border-0 bg_darkblue px-5 py-2 roundd-2 fs_16 ff_heebo fw-medium text-white"
                  type="submit"
                >
                  Submit
                </button>
              </span>
            </form>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={showVideoModal}
        onHide={() => setShowVideoModal(false)}
        centered
      >
        <div
          style={{ width: "100%", margin: "0 auto" }}
          className="bg-white rounded-3 "
        >
          <Modal.Header closeButton>
            <Modal.Title>Play Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              {selectedFile && (
                <video controls style={{ width: "100%" }}>
                  <source
                    src={`https://api.koduapp.com/public/images/${selectedFile}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowVideoModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default CourseTab;
