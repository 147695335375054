import React, { useCallback, useEffect, useState } from "react";
import makeApiCall from "../callApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiyContentSelect from "./MultiyContentSelect";
import edit_icon from "../../assets/images/png/edit_icon.png";
import delete_icon from "../../assets/images/png/delete_icon.png";
import crossblack_icon from "../../assets/images/png/blackcross_icon.png";
import { Button, Modal } from "react-bootstrap";

const CourseVideo = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const [addCourseModal, setAddCourseModal] = useState(false);
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setUploadStatus(""); // Clear previous status
  };

  const handleUpload = async () => {
    if (!title || !description || selectedCourses.length === 0) {
      setUploadStatus("Please fill all fields before uploading.");
      return;
    }

    if (selectedFiles.length === 0) {
      setUploadStatus("Please select video files to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    selectedCourses.forEach((course) => {
      formData.append("courseIds[]", course.id);
    });

    selectedFiles.forEach((file) => {
      formData.append("video", file);
    });

    setIsUploading(true);
    try {
      const response = await makeApiCall(
        formData,
        "post",
        "admin/updateVideo",
        (response) => {
          if (response && response.data.status) {
            toast.success(response.data.message);
            setSelectedFiles([]); // Clear files after upload
            setTitle("");
            setDescription("");
            setSelectedCourses([]);
          } else {
            toast.error(response?.message || "An unexpected error occurred.");
          }
        }
      );
    } catch (error) {
      console.error("Upload error:", error);
      toast.error(error.message || "An error occurred during upload.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove a specific file
  };
  const [coursesList, SetCoursesList] = useState([]);
  async function getCourse() {
    await makeApiCall("", "get", "admin/videoList", coursesListCallBack);
  }

  const coursesListCallBack = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      SetCoursesList(response.data.data);
    } else {
      console.log("error");
    }
  });

  const coursesDeleteCallBack = useCallback(
    (response) => {
      console.log(response);
      if (response.status === 200) {
        console.log("Course deleted successfully");
        getCourse();
      } else {
        console.error("Error deleting course");
      }
    },
    [getCourse]
  );

  async function deleteCourse(id) {
    await makeApiCall(
      "",
      "get",
      `admin/deleteVideo/${id}`,
      coursesDeleteCallBack
    );
  }

  useEffect(() => {
    getCourse();
  }, []);
  const handlePlayClick = (video) => {
    setSelectedFile(video);
    setShowVideoModal(true);
  };
  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  return (
    <div>
      <ToastContainer />
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="fs_37 fw-semibold ff_heebo">Courses Video</h2>
        </div>
        <div className="border mb-4 mt-3"></div>
      </div>
      {!addCourseModal ? (
        <>
          {" "}
          <button
            onClick={() => setAddCourseModal(true)}
            className="bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold mt-3"
          >
            Add New Video
          </button>
          <div
            className="card rounded-3 mt-4"
            style={{ maxWidth: "1300px", overflowX: "auto" }}
          >
            <table className="w-100">
              <tr>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  Title
                </th>

                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  Description
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  Video
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  Action
                </th>
              </tr>
              {coursesList.length < 1 ? (
                <div className="text-start ps-3 w-100 py-4">
                  <p>No data found</p>
                </div>
              ) : (
                <>
                  {coursesList.map((obj, i) => (
                    <tr key={i}>
                      <td className="px-3 py-3">{obj.title}</td>
                      <td className="px-3 py-3">{obj.description}</td>
                      <td className="px-3 py-3">
                        {obj.video ? (
                          <>
                            <button
                              onClick={() => handlePlayClick(obj.video)}
                              className=" bg-success py-2 px-4 border-0 ff_heebo text-white fw-bold"
                            >
                              Play Video
                            </button>
                          </>
                        ) : (
                          <>Na</>
                        )}
                      </td>
                      <td className="px-3 py-3">
                        <div className="d-flex gap-3 bg-white">
                          <img
                            onClick={() => {
                              toggleFreeTrialModal();
                              setCurrentData(obj);
                            }}
                            width={20}
                            className="cursor_pointer"
                            src={edit_icon}
                            alt=""
                          />
                          <img
                            onClick={() => deleteCourse(obj._id)}
                            width={20}
                            className="cursor_pointer"
                            src={delete_icon}
                            alt=""
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </table>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="card rounded-0 p-4 position-relative">
            <div className="position-absolute top-0 end-0">
              <img
                onClick={() => setAddCourseModal(false)}
                width={20}
                className="me-3 mt-3 cursor_pointer"
                src={crossblack_icon}
                alt="Close"
              />
            </div>
            <h5>Add Course Video</h5>
            <div className="row">
              <div className="d-flex col-md-6 flex-column mt-4">
                <label htmlFor="Tital">--Title--</label>
                <input
                  required
                  className="border mt-2 border-black p-2 rounded-2"
                  type="text"
                  placeholder="--Title--"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="d-flex col-md-6 flex-column mt-4">
                <label className="mb-2" htmlFor="courseSelect">
                  --Select Courses--
                </label>
                <MultiyContentSelect
                  selectedValues={selectedCourses}
                  setSelectedValues={setSelectedCourses}
                />
              </div>
            </div>

            <div className="mt-4">
              <label htmlFor="videoUpload" className="form-label">
                Upload Course Videos
              </label>
              <input
                required
                type="file"
                id="videoUpload"
                className="form-control border-black"
                multiple
                accept="video/*"
                onChange={handleFileChange}
              />
            </div>
            <div className="col-12">
              <div className="d-flex flex-column mt-4">
                <label htmlFor="Description">--Description--</label>
                <textarea
                  required
                  rows={4}
                  className="border mt-2 border-black p-2 rounded-2"
                  type="text"
                  placeholder="--Description--"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            {selectedFiles.length > 0 && (
              <div className="mt-3">
                <h6>Selected Videos:</h6>
                <ul className="ps-0 row">
                  {selectedFiles.map((file, index) => (
                    <div className="col-4 mt-3" key={index}>
                      <div className="card h-100 rounded-0 p-3">
                        <li className="d-flex align-items-center">
                          {file.name}
                          <button
                            className="btn btn-sm btn-danger ms-2"
                            onClick={() => handleRemoveFile(index)}
                          >
                            Remove
                          </button>
                        </li>
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
            )}

            <button
              className="bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold mt-3"
              onClick={handleUpload}
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Upload Videos"}
            </button>

            {uploadStatus && (
              <div
                className={`mt-3 alert ${
                  uploadStatus.includes("successfully")
                    ? "alert-success"
                    : "alert-danger"
                }`}
              >
                {uploadStatus}
              </div>
            )}
          </div>
        </>
      )}
      <Modal
        show={showVideoModal}
        onHide={() => setShowVideoModal(false)}
        centered
      >
        <div
          style={{ width: "100%", margin: "0 auto" }}
          className="bg-white rounded-3 "
        >
          <Modal.Header closeButton>
            <Modal.Title>Play Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              {selectedFile && (
                <video controls style={{ width: "100%" }}>
                  <source
                    src={`https://api.koduapp.com/public/images/${selectedFile}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default CourseVideo;
