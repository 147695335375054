import React, { useContext, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "../../assets/images/png/logo1.png";
import blog_icon from "../../assets/images/svg/blog_icon.svg";
import tranier_icon from "../../assets/images/svg/trainer_icon.svg";
import content_icon from "../../assets/images/svg/content_icon.svg";
import logout_icon from "../../assets/images/svg/logout_icon.svg";
import course_icon from "../../assets/images/svg/course_icon.svg";
import test_icon from "../../assets/images/svg/test_icon.svg";
import quiz_icon from "../../assets/images/svg/quiz_icon.svg";
import user_icon from "../../assets/images/svg/user_icon.svg";
import lead_icon from "../../assets/images/svg/lead_icon.svg";
import { AppContext } from "../../context/Context";
import { Navbar, Offcanvas, Container } from "react-bootstrap";
function AdminMainTemplate({ children }) {
  const context = useContext(AppContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState("courses");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setShowSidebar(false);
  };
  const { setAuth } = context;
  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem("admtoken");
    setAuth(false);
    navigate("/admin");
  }

  return (
    <div className=" container-fluid ">
      <Navbar variant="dark" expand="md" className="d-md-none bg_darkblue">
        <Container>
          <Navbar.Brand href="#">
            <img width={100} src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvas-nav"
            onClick={() => setShowSidebar(!showSidebar)}
          />
          <Navbar.Offcanvas
            className="bg_darkblue"
            id="offcanvas-nav"
            aria-labelledby="offcanvas-nav-label"
            placement="start"
            show={showSidebar}
            onHide={() => setShowSidebar(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvas-nav-label">
                s
                <img width={100} src={logo} alt="logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="d-flex flex-column justify-content-between h-100">
                <div>
                  <ul className="nav flex-column  px-3 ps-0">
                    <Link to={"/admin/course-tab"}>
                      <div
                        onClick={() => setShowSidebar(!showSidebar)}
                        className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer `}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img width={20} src={course_icon} alt="course_icon" />
                        </span>
                        <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                          Courses
                        </span>
                      </div>
                    </Link>
                    <Link to={"/admin/course-video-tab"}>
                      <div
                        onClick={() => setShowSidebar(!showSidebar)}
                        className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer `}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img width={20} src={course_icon} alt="course_icon" />
                        </span>
                        <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                          Courses Video
                        </span>
                      </div>
                    </Link>
                    <Link to={"/admin/blog-tab"}>
                      <div
                        onClick={() => setShowSidebar(!showSidebar)}
                        className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer `}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img width={20} src={blog_icon} alt="blog_icon" />
                        </span>
                        <span className="text-white mt-1 ff_heebo fw-medium ">
                          Blogs
                        </span>
                      </div>
                    </Link>
                    <Link to={"/admin/trainers-list"}>
                      <div
                        onClick={() => setShowSidebar(!showSidebar)}
                        className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer `}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img
                            width={20}
                            src={tranier_icon}
                            alt="tranier_icon"
                          />
                        </span>
                        <span className="text-white ff_heebo fw-medium cursor_pointer">
                          Trainers
                        </span>
                      </div>
                    </Link>
                    <Link to={"/admin/content"}>
                      <div
                        onClick={() => setShowSidebar(!showSidebar)}
                        className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer `}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img
                            width={20}
                            src={content_icon}
                            alt="content_icon"
                          />
                        </span>
                        <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                          Course Content
                        </span>
                      </div>
                    </Link>
                    <Link to={"/admin/test-tab"}>
                      <div
                        className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer `}
                        onClick={() => setShowSidebar(!showSidebar)}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img width={25} src={test_icon} alt="test_icon" />
                        </span>
                        <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                          Test
                        </span>
                      </div>
                    </Link>
                    <Link to={"/admin/quiz-tab"}>
                      <div
                        className={`nav-item py-1 px-3 mt-3 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                        onClick={() => setShowSidebar(!showSidebar)}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img width={20} src={quiz_icon} alt="quiz_icon" />
                        </span>
                        <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                          Questions
                        </span>
                      </div>
                    </Link>

                    <Link to={"/admin/user-tab"}>
                      <div
                        className={`nav-item py-1 px-3 mt-3 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                        onClick={() => setShowSidebar(!showSidebar)}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img width={25} src={user_icon} alt="quiz_icon" />
                        </span>
                        <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                          Users
                        </span>
                      </div>
                    </Link>
                    <Link to={"/admin/leads-tab"}>
                      <div
                        className={`nav-item py-1 px-3 mt-3 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                        onClick={() => setShowSidebar(!showSidebar)}
                      >
                        <span className="text-white ff_heebo fw-medium ">
                          <img width={30} src={lead_icon} alt="quiz_icon" />
                        </span>
                        <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                          Leads
                        </span>
                      </div>
                    </Link>
                  </ul>
                </div>
                <div
                  onClick={logout}
                  className="ps-4 mx-3 nav-item d-flex cursor_pointer mb-2 align-items-center gap-2 p-2 mt-3"
                >
                  <span className=" cursor_pointer mb-0 ff_heebo fw-semibold  text-white border-0 ">
                    <img width={20} src={logout_icon} alt="logout_icon" />
                  </span>
                  <span className=" cursor_pointer mb-0 ff_heebo fw-semibold  text-white border-0 ">
                    Logout
                  </span>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <div>
        <div className="row">
          <div
            className="col-xl-2  col-md-3 d-none d-md-block py-4 px-0 bg_darkblue min-vh-100"
            style={{ height: "100vh", overflow: "hidden" }}
          >
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <a className="px-4" href="#">
                  <img className="mb-1" width={100} src={logo} alt="logo" />
                </a>
                <div className="border mt-4"></div>
                <ul className="nav flex-column mt-2 px-3">
                  <Link
                    to={"/admin/course-tab"}
                    className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer `}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={20} src={course_icon} alt="course_icon" />
                    </span>
                    <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                      Courses
                    </span>
                  </Link>
                  <Link
                    to={"/admin/course-video-tab"}
                    className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer `}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={20} src={course_icon} alt="course_icon" />
                    </span>
                    <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                      Courses Video
                    </span>
                  </Link>
                  <Link
                    to={"/admin/blog-tab"}
                    className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={20} src={blog_icon} alt="blog_icon" />
                    </span>
                    <span className="text-white mt-1 ff_heebo fw-medium ">
                      Blogs
                    </span>
                  </Link>
                  <Link
                    to={"/admin/trainers-list"}
                    className={`nav-item py-1 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={20} src={tranier_icon} alt="tranier_icon" />
                    </span>
                    <span className="text-white ff_heebo fw-medium cursor_pointer">
                      Trainers
                    </span>
                  </Link>
                  <Link
                    to={"/admin/content"}
                    className={`nav-item py-1 px-3 mt-3 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={20} src={content_icon} alt="content_icon" />
                    </span>
                    <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                      Course Content
                    </span>
                  </Link>
                  <Link
                    to={"/admin/test-tab"}
                    className={`nav-item py-1 px-3 mt-3 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={25} src={test_icon} alt="test_icon" />
                    </span>
                    <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                      Test
                    </span>
                  </Link>
                  <Link
                    to={"/admin/quiz-tab"}
                    className={`nav-item py-1 px-3 mt-3 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={20} src={quiz_icon} alt="quiz_icon" />
                    </span>
                    <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                      Questions
                    </span>
                  </Link>

                  <Link
                    to={"/admin/user-tab"}
                    className={`nav-item py-1 px-3 mt-3 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={25} src={user_icon} alt="quiz_icon" />
                    </span>
                    <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                      Users
                    </span>
                  </Link>
                  <Link
                    to={"/admin/leads-tab"}
                    className={`nav-item py-1 px-3 mt-3 d-flex align-items-center gap-3 px-3 mt-3 cursor_pointer`}
                  >
                    <span className="text-white ff_heebo fw-medium ">
                      <img width={30} src={lead_icon} alt="quiz_icon" />
                    </span>
                    <span className="text-white mt-1 ff_heebo fw-medium cursor_pointer">
                      Leads
                    </span>
                  </Link>
                </ul>
              </div>
              <div
                onClick={logout}
                className="ps-4 mx-3 nav-item d-flex cursor_pointer mb-2 align-items-center gap-2 p-2 mt-3"
              >
                <span className=" cursor_pointer mb-0 ff_heebo fw-semibold  text-white border-0 ">
                  <img width={20} src={logout_icon} alt="logout_icon" />
                </span>
                <span className=" cursor_pointer mb-0 ff_heebo fw-semibold  text-white border-0 ">
                  Logout
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-xl-10 overflow-y-auto col-md-9 p-md-3 px-sm-5 px-4 pt-4 min-vh-100"
            style={{ height: "100vh" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMainTemplate;
