import React, { useCallback, useContext, useState } from "react";
import makeApiCall from "../callApi";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import cross_icon from "../../assets/images/png/cross_icon.jpg";
import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/Context";

const LoginModal = () => {
  //   const [phoneNumber, setPhoneNumber] = useState("");
  //   const [otp, setOtp] = useState("");
  const [isUserCheck, setIsUserCheck] = useState("");
  const [logOut, setLogOut] = useState(false);
  const { isLoginPopup, setIsLoginPopup, setIsLogin } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [showStudentInfoInput, setShowStudentInfoInput] = useState(false);
  // auth

  const [sendOTP, setSendOTP] = useState({
    phone: "",
  });
  const [otpValue] = useState("");

  const handleInputChangeAuth = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setSendOTP((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setSendOTP((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const sendOTPAuth = async (e) => {
    e.preventDefault();
    // handleSendOTP();
    const otpdata = {
      phone: sendOTP.phone,
    };

    try {
      const res = await makeApiCall(
        otpdata,
        "post",
        "sendOTP",
        createOTPCallBack,
        false
      );
      // createOTPCallBack(res);
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setShowOTPInput(false);
    setShowStudentInfoInput(false);
    setIsOtpVerified(false);
  };

  const createOTPCallBack = useCallback((res) => {
    if (res.data.status === "success") {
      let setUserCheck = res.data.user_check === 0 ? "signup" : "login";
      toast.success(res.data.message);
      setIsUserCheck(setUserCheck);
      console.log(res.data.user_check);
      setIsOtpVerified(true);
      setShowOTPInput(true);
      setSendOTP({
        phone: "",
      });
      setVerifyOTP((prevData) => ({
        ...prevData,
        phone: sendOTP.phone, // Ensuring consistency with the phone number
        otp: "",
        type: isUserCheck,
      }));
      setRegister((prevData) => ({
        ...prevData,
        phone: sendOTP.phone,
      }));
    } else {
      toast.error(res.data.message);
    }
  });

  //

  const [verifyOTP, setVerifyOTP] = useState({
    phone: "",
    otp: "",
    type: isUserCheck,
  });
  const [verifyOTPValue] = useState("");
  const handleInputChangeverifyOTP = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setVerifyOTP((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setVerifyOTP((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const verifyOTPAuth = async (e) => {
    e.preventDefault();
    const otpdata = {
      phone: verifyOTP.phone,
      otp: verifyOTP.otp,
      type: isUserCheck,
    };

    try {
      const res = await makeApiCall(
        otpdata,
        "post",
        "verifyOTP",
        verifyOTPCallBack,
        false
      );
      // verifyOTPCallBack(res);
    } catch (error) {
      toast.error("OTP verification failed. Please try again later.");
    }
  };

  const verifyOTPCallBack = useCallback((res) => {
    if (res.data.status === "success") {
      toast.success(res.data.message);
      if (isUserCheck === "signup") {
        setShowStudentInfoInput(true);
        setShowOTPInput(false);
        setVerifyOTP(false);
        setVerifyOTP({
          // phone: "",
          otp: "",
          type: "register",
        });
        setRegister((prevData) => ({
          ...prevData,
          otp: verifyOTP.otp,
        }));
      } else {
        setIsLogin(true);
        setShowModal(false);
        setShowOTPInput(false);
        setVerifyOTP(false);
        setLogOut(true);
        setIsLoginPopup(false);
        localStorage.setItem("authtoken", res?.data?.token);
        localStorage.setItem("userData", JSON.stringify(res?.data?.user));
        console.log(res?.data.user);
      }
    } else {
      toast.error(res.data.message);
    }
  });

  const [register, setRegister] = useState({
    name: "",
    qualification: "",
    phone: "",
    course: "",
    otp: "",
    city: "",
  });
  const handleInputChangeRegister = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setRegister((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setRegister((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const registerAuth = async (e) => {
    e.preventDefault();
    const registerdata = {
      phone: register.phone,
      otp: register.otp,
      name: register.name,
      qualification: register.qualification,
      course: register.course,
      city: register.city,
    };

    try {
      const res = await makeApiCall(
        registerdata,
        "post",
        "register",
        registerCallBack,
        false
      );
      // registerCallBack(res);
    } catch (error) {
      toast.error("OTP verification failed. Please try again later.");
    }
  };

  const registerCallBack = useCallback((res) => {
    if (res.data.status === "success") {
      localStorage.setItem("authtoken", res?.data?.token);
      localStorage.setItem("userData", res?.data?.data);
      console.log(res?.data);
      setIsLogin(true);
      toast.success(res.data.message);
      setShowModal(false);
      setShowOTPInput(false);
      setVerifyOTP(false);
      setLogOut(true);
      setIsLoginPopup(false);
      setRegister({
        name: "",
        qualification: "",
        phone: "",
        course: "",
        otp: "",
        city: "",
      });
    } else {
      toast.error(res.data.message);
    }
  });

  function webLogOut() {
    localStorage.removeItem("admtoken");
    setLogOut(false);
  }
  return (
    <div>
      <div className="login_modal">
        <ToastContainer />
        <Modal show={true} centered onHide={() => setIsLoginPopup(false)}>
          <div
            style={{
              maxWidth: "500px",
              margin: "0 auto",
              backgroundColor: "transparent",
            }}
            className="rounded-3 px-4 w-100 bg_darkblue position-relative"
          >
            <div className="position-absolute top-0 end-0">
              <img
                onClick={() => setIsLoginPopup(false)}
                width={20}
                className="me-3 mt-3 cursor_pointer"
                src={crosswhtie_icon}
                alt="crosswhtie_icon"
              />
            </div>
            <Modal.Header className="border-0 text-white pb-0 pt-5">
              <Modal.Title>
                <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-white">
                  Login / Signup
                </h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className=" border-0 pt-0">
              {showOTPInput && (
                <div className="mt-4">
                  <div className="d-flex flex-column mt-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className=" text-white mb-2" htmlFor="Name">
                        Enter OTP
                      </label>
                      <p className="mb-0 ff_heebo text-white">
                        Did’nt Recive OTP?
                        <span className=" cursor_pointer color_red">
                          Resend
                        </span>
                      </p>
                    </div>
                    <input
                      required
                      className="px-3 py-2 w-100 rounded-2"
                      type="number"
                      placeholder="Enter OTP"
                      value={verifyOTP?.otp}
                      name="otp"
                      onChange={handleInputChangeverifyOTP}
                    />
                  </div>

                  <button
                    onClick={verifyOTPAuth}
                    className="w-100 py-2 bg_yellow mt-5 text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
                  >
                    Verify OTP
                  </button>
                </div>
              )}
              {showStudentInfoInput && (
                <>
                  <div className="d-flex flex-column mt-4">
                    <label className=" text-white mb-2" htmlFor="Name">
                      Name
                    </label>
                    <input
                      required
                      className="px-3 py-2 w-100 rounded-2"
                      type="text"
                      placeholder="Enter Name"
                      value={register?.name}
                      name="name"
                      onChange={handleInputChangeRegister}
                    />
                  </div>

                  <div className="d-flex flex-column mt-3">
                    <label className=" text-white mb-2" htmlFor="Name">
                      Qualification
                    </label>
                    <input
                      required
                      className="px-3 py-2 w-100 rounded-2"
                      type="text"
                      placeholder=""
                      value={register?.qualification}
                      name="qualification"
                      onChange={handleInputChangeRegister}
                    />
                  </div>
                  <div className="d-flex flex-column mt-3">
                    <label className=" text-white mb-2" htmlFor="Name">
                      Intrested Course
                    </label>

                    <select
                      required
                      className="w-100 text-start rounded-2 py-2 px-3 justify-content-between d-flex align-items-center bg-white color_darkgray"
                      id="dropdown-basic"
                      value={register?.course}
                      name="course"
                      onChange={handleInputChangeRegister}
                    >
                      Intrested Course
                      <option value="Hisar">Hisar</option>
                      <option value="Option1">Option1</option>
                      <option value="Opction2">Opction2</option>
                    </select>
                  </div>
                  <div className="d-flex flex-column mt-3">
                    <label className=" text-white mb-2" htmlFor="Name">
                      City
                    </label>
                    <select
                      required
                      className="w-100 text-start rounded-2 py-2 px-3 justify-content-between d-flex align-items-center bg-white color_darkgray"
                      id="dropdown-basic"
                      value={register?.city}
                      name="city"
                      onChange={handleInputChangeRegister}
                    >
                      City
                      <option value="Hisar">Hisar</option>
                      <option value="Option1">Option1</option>
                      <option value="Opction2">Opction2</option>
                    </select>
                  </div>
                  <button
                    onClick={registerAuth}
                    className="w-100 py-2 mt-5 bg_yellow text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
                  >
                    Submit
                  </button>
                </>
              )}

              {!showOTPInput && !isOtpVerified && (
                <>
                  <input
                    required
                    className="px-3 mt-4 py-2 w-100 rounded-2"
                    type="number"
                    placeholder="Enter Mobile"
                    value={sendOTP?.phone}
                    name="phone"
                    onChange={handleInputChangeAuth}
                  />
                  <button
                    onClick={sendOTPAuth}
                    className="w-100 py-2 mt-3 bg_yellow text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
                  >
                    Send OTP
                  </button>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0 pb-4 w-100"></Modal.Footer>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default LoginModal;
// import React, { useCallback, useContext, useState } from "react";
// import makeApiCall from "../callApi";
// import { ToastContainer, toast } from "react-toastify";
// import { Modal } from "react-bootstrap";
// import cross_icon from "../../assets/images/png/cross_icon.jpg";
// import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";
// import { Link } from "react-router-dom";
// import { AppContext } from "../../context/Context";

// const LoginModal = () => {
//   const [isUserCheck, setIsUserCheck] = useState("");
//   const [logOut, setLogOut] = useState(false);
//   const { isLoginPopup, setIsLoginPopup, setIsLogin } = useContext(AppContext);
//   const [showModal, setShowModal] = useState(false);
//   const [showOTPInput, setShowOTPInput] = useState(false);
//   const [isOtpVerified, setIsOtpVerified] = useState(false);
//   const [showStudentInfoInput, setShowStudentInfoInput] = useState(false);

//   const [sendOTP, setSendOTP] = useState({
//     phone: "",
//   });
//   const [otpValue, setOtpValue] = useState("");

//   const handleInputChangeAuth = (e) => {
//     const { name, value } = e.target;
//     setSendOTP((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const sendOTPAuth = async (e) => {
//     e.preventDefault();
//     const otpdata = {
//       phone: sendOTP.phone,
//     };

//     try {
//       const res = await makeApiCall(
//         otpdata,
//         "post",
//         "sendOTP",
//         createOTPCallBack,
//         false
//       );
//     } catch (error) {
//       toast.error("Failed to create lead. Please try again later.");
//     }
//   };

//   const toggleModal = () => {
//     setShowModal(!showModal);
//     setShowOTPInput(false);
//     setShowStudentInfoInput(false);
//     setIsOtpVerified(false);
//   };

//   const createOTPCallBack = useCallback(
//     (res) => {
//       if (res.data.status === "success") {
//         toast.success(res.data.message);
//         setIsUserCheck(res.data.user_check);
//         setIsOtpVerified(true);
//         setShowOTPInput(true);
//         setSendOTP({ phone: "" });
//         setVerifyOTP({
//           phone: sendOTP.phone,
//           otp: "",
//           type: res.data.user_check === 0 ? "register" : "login",
//         });
//         if (res.data.user_check === 0) {
//           setRegister((prevData) => ({
//             ...prevData,
//             phone: sendOTP.phone,
//           }));
//         }
//       } else {
//         toast.error(res.data.message);
//       }
//     },
//     [sendOTP.phone]
//   );

//   const [verifyOTP, setVerifyOTP] = useState({
//     phone: "",
//     otp: "",
//     type: "", // This will be set dynamically in the callback
//   });

//   const handleInputChangeverifyOTP = (e) => {
//     const { name, value } = e.target;
//     setVerifyOTP((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const verifyOTPAuth = async (e) => {
//     e.preventDefault();
//     const otpdata = {
//       phone: verifyOTP.phone,
//       otp: verifyOTP.otp,
//       type: isUserCheck === 0 ? "register" : "login",
//     };

//     try {
//       const res = await makeApiCall(
//         otpdata,
//         "post",
//         "verifyOTP",
//         verifyOTPCallBack,
//         false
//       );
//     } catch (error) {
//       toast.error("OTP verification failed. Please try again later.");
//     }
//   };

//   const verifyOTPCallBack = useCallback(
//     (res) => {
//       if (res.data.status === "success") {
//         alert(res.data.message);
//         setShowStudentInfoInput(isUserCheck === 0);
//         setShowOTPInput(false);
//         setVerifyOTP({
//           phone: "",
//           otp: "",
//           type: "",
//         });
//         if (isUserCheck === 1) {
//           setIsLogin(true);
//           setIsLoginPopup(false);
//           localStorage.setItem("token", res.data.token);
//         } else {
//           setRegister((prevData) => ({
//             ...prevData,
//             otp: verifyOTP.otp,
//           }));
//         }
//       } else {
//         toast.error(res.data.message);
//       }
//     },
//     [isUserCheck, verifyOTP.otp]
//   );

//   const [register, setRegister] = useState({
//     name: "",
//     qualification: "",
//     phone: "",
//     course: "",
//     otp: "",
//     city: "",
//   });

//   const handleInputChangeRegister = (e) => {
//     const { name, value } = e.target;
//     setRegister((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const registerAuth = async (e) => {
//     e.preventDefault();
//     const registerdata = {
//       phone: register.phone,
//       otp: register.otp,
//       name: register.name,
//       qualification: register.qualification,
//       course: register.course,
//       city: register.city,
//     };

//     try {
//       const res = await makeApiCall(
//         registerdata,
//         "post",
//         "register",
//         registerCallBack,
//         false
//       );
//     } catch (error) {
//       toast.error("Registration failed. Please try again later.");
//     }
//   };

//   const registerCallBack = useCallback((res) => {
//     if (res.data.status === "success") {
//       localStorage.setItem("token", res?.data?.token);
//       setIsLogin(true);
//       toast.success(res.data.message);
//       setShowModal(false);
//       setShowOTPInput(false);
//       setLogOut(true);
//       setIsLoginPopup(false);
//       setRegister({
//         name: "",
//         qualification: "",
//         phone: "",
//         course: "",
//         otp: "",
//         city: "",
//       });
//     } else {
//       toast.error(res.data.message);
//     }
//   }, []);

//   function webLogOut() {
//     localStorage.removeItem("authtoken");
//     setLogOut(false);
//   }

//   return (
//     <div>
//       <div className="login_modal">
//         <ToastContainer />
//         <Modal show={true} centered onHide={() => setIsLoginPopup(false)}>
//           <div
//             style={{
//               maxWidth: "500px",
//               margin: "0 auto",
//               backgroundColor: "transparent",
//             }}
//             className="rounded-3 px-4 w-100 bg_darkblue position-relative"
//           >
//             <div className="position-absolute top-0 end-0">
//               <img
//                 onClick={() => setIsLoginPopup(false)}
//                 width={20}
//                 className="me-3 mt-3 cursor_pointer"
//                 src={crosswhtie_icon}
//                 alt="crosswhtie_icon"
//               />
//             </div>
//             <Modal.Header className="border-0 text-white pb-0 pt-5">
//               <Modal.Title>
//                 <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-white">
//                   Login / Signup
//                 </h3>
//               </Modal.Title>
//             </Modal.Header>
//             <Modal.Body className=" border-0 pt-0">
//               {showOTPInput && (
//                 <div className="mt-4">
//                   <div className="d-flex flex-column mt-4">
//                     <div className="d-flex justify-content-between align-items-center">
//                       <label className=" text-white mb-2" htmlFor="Name">
//                         Enter OTP
//                       </label>
//                       <p className="mb-0 ff_heebo text-white">
//                         Didn’t receive OTP?
//                         <span className=" cursor_pointer color_red">
//                           Resend
//                         </span>
//                       </p>
//                     </div>
//                     <input
//                       required
//                       className="px-3 py-2 w-100 rounded-2"
//                       type="number"
//                       placeholder="Enter OTP"
//                       value={verifyOTP?.otp}
//                       name="otp"
//                       onChange={handleInputChangeverifyOTP}
//                     />
//                   </div>

//                   <button
//                     onClick={verifyOTPAuth}
//                     className="w-100 py-2 bg_yellow mt-5 text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
//                   >
//                     Verify OTP
//                   </button>
//                 </div>
//               )}
//               {showStudentInfoInput && (
//                 <>
//                   <div className="d-flex flex-column mt-4">
//                     <label className=" text-white mb-2" htmlFor="Name">
//                       Name
//                     </label>
//                     <input
//                       required
//                       className="px-3 py-2 w-100 rounded-2"
//                       type="text"
//                       placeholder="Enter Name"
//                       value={register?.name}
//                       name="name"
//                       onChange={handleInputChangeRegister}
//                     />
//                   </div>

//                   <div className="d-flex flex-column mt-3">
//                     <label className=" text-white mb-2" htmlFor="Name">
//                       Qualification
//                     </label>
//                     <input
//                       required
//                       className="px-3 py-2 w-100 rounded-2"
//                       type="text"
//                       placeholder=""
//                       value={register?.qualification}
//                       name="qualification"
//                       onChange={handleInputChangeRegister}
//                     />
//                   </div>
//                   <div className="d-flex flex-column mt-3">
//                     <label className=" text-white mb-2" htmlFor="Name">
//                       Interested Course
//                     </label>

//                     <select
//                       required
//                       className="w-100 text-start rounded-2 py-2 px-3 justify-content-between d-flex align-items-center bg-white color_darkgray"
//                       id="dropdown-basic"
//                       value={register?.course}
//                       name="course"
//                       onChange={handleInputChangeRegister}
//                     >
//                       Interested Course
//                       <option value="Select Course">Select Course</option>
//                       <option value="UI UX designing">UI UX designing</option>
//                       <option value="Web Development">Web Development</option>
//                       <option value="Frontend Development">
//                         Frontend Development
//                       </option>
//                       <option value="Backend Development">
//                         Backend Development
//                       </option>
//                       <option value="Mobile App Development">
//                         Mobile App Development
//                       </option>
//                     </select>
//                   </div>
//                   <div className="d-flex flex-column mt-3">
//                     <label className=" text-white mb-2" htmlFor="Name">
//                       City
//                     </label>
//                     <select
//                       required
//                       className="w-100 text-start rounded-2 py-2 px-3 justify-content-between d-flex align-items-center bg-white color_darkgray"
//                       id="dropdown-basic"
//                       value={register?.city}
//                       name="city"
//                       onChange={handleInputChangeRegister}
//                     >
//                       City
//                       <option value="Select City">Select City</option>
//                       <option value="Hisar">Hisar</option>
//                       <option value="Rohtak">Rohtak</option>
//                       <option value="Sirsa">Sirsa</option>
//                       <option value="Sikar">Sikar</option>
//                       <option value="Jaipur">Jaipur</option>
//                     </select>
//                   </div>
//                   <button
//                     onClick={registerAuth}
//                     className="w-100 py-2 mt-5 bg_yellow text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
//                   >
//                     Submit
//                   </button>
//                 </>
//               )}

//               {!showOTPInput && !isOtpVerified && (
//                 <>
//                   <input
//                     required
//                     className="px-3 mt-4 py-2 w-100 rounded-2"
//                     type="number"
//                     placeholder="Enter Mobile"
//                     value={sendOTP?.phone}
//                     name="phone"
//                     onChange={handleInputChangeAuth}
//                   />
//                   <button
//                     onClick={sendOTPAuth}
//                     className="w-100 py-2 mt-3 bg_yellow text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
//                   >
//                     Send OTP
//                   </button>
//                 </>
//               )}
//             </Modal.Body>
//             <Modal.Footer className="border-0 pt-0 pb-4 w-100"></Modal.Footer>
//           </div>
//         </Modal>
//       </div>
//     </div>
//   );
// };

// export default LoginModal;
