import React, { useCallback, useContext, useState } from "react";
import logo from "../../assets/images/svg/logo.svg";
import cross_icon from "../../assets/images/png/cross_icon.jpg";
import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";
import { Link } from "react-router-dom";
import { Modal, Button, Dropdown, Spinner } from "react-bootstrap";
import makeApiCall from "../callApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../context/Context";

const Navbar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [showStudentInfoInput, setShowStudentInfoInput] = useState(false);
  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const { isLoginPopup, setIsLoginPopup, isLogin } = useContext(AppContext);
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [otp, setOtp] = useState("");
  // const [isOtpSent, setIsOtpSent] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setShowOTPInput(false);
    setShowStudentInfoInput(false);
    setIsOtpVerified(false);
  };

  const handleSendOTP = () => {
    setShowOTPInput(true);
  };

  const handleVerifyOTP = () => {
    const otpVerified = true;

    if (otpVerified) {
      setShowStudentInfoInput(true);
      setShowOTPInput(false);
      setIsOtpVerified(true);
    } else {
    }
  };
  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };
  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    qualification: "",
    course: "",
    nearestCenter: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setformData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setformData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const { name, email, phone, topic, qualification, course, nearestCenter } =
      formData;
    if (
      !name ||
      !email ||
      !phone ||
      !topic ||
      !qualification ||
      !course ||
      !nearestCenter
    ) {
      toast.error("All fields are required");
      return false;
    }
    if (
      qualification === " -- Select Qualification --" ||
      course === " -- Select Course --" ||
      nearestCenter === " -- Select Nearest Center --"
    ) {
      toast.error("Please select valid options for all dropdowns");
      return false;
    }
    return true;
  };

  const createForm = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const formdata = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      topic: formData.topic,
      qualification: formData.qualification,
      course: formData.course,
      nearestCenter: formData.nearestCenter,
    };

    try {
      const res = await makeApiCall(
        formdata,
        "post",
        "new_lead",
        createFormCallBack,
        false
      );
      // createFormCallBack(res);
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
      setIsLoading(false);
    }
  };

  const createFormCallBack = useCallback((res) => {
    setIsLoading(false);
    if (res.data.status === "success") {
      toast.success(
        "Congratulations! Your request has been submitted successfully!"
      );
      setformData({
        name: "",
        email: "",
        phone: "",
        topic: "",
        qualification: "",
        course: "",
        nearestCenter: "",
      });
    } else {
      toast.error(res.data.message);
    }
  });

  return (
    <div className=" bg_darkblue py-3">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/">
            <img className=" cursor_pointer" src={logo} alt="kodu web logo" />
          </Link>
          <div className="d-flex align-items-center ">
            <ul className="d-lg-flex d-none align-items-center gap-5 mb-0 ps-0">
              <Link to="/course">
                <li>
                  <a
                    className=" fs_17 ff_heebo color_lightwhite fw-medium after_line d-inline position-relative"
                    href="#"
                  >
                    View Courses
                  </a>
                </li>
              </Link>
              <Link to="/scholarshipPage-page">
                <li>
                  <a
                    className=" fs_17 ff_heebo color_lightwhite fw-medium after_line d-inline position-relative "
                    href=""
                  >
                    Scholarship
                  </a>
                </li>
              </Link>
              <Link to="/centers">
                <li>
                  <a
                    className=" fs_17 ff_heebo color_lightwhite fw-medium after_line d-inline position-relative "
                    href=""
                  >
                    Centers
                  </a>
                </li>
              </Link>
              <Link to="/quiz-page">
                <li>
                  <a
                    className=" fs_17 ff_heebo  color_lightwhite fw-medium after_line d-inline position-relative "
                    href=""
                  >
                    Quiz
                  </a>
                </li>
              </Link>
            </ul>
          </div>
          <div className="d-sm-flex d-none gap-3 align-items-center">
            {!isLogin ? (
              <>
                <button
                  onClick={() => setIsLoginPopup(true)}
                  className="login_btn d-sm-block d-none fs_17 fw-medium ff_heebo color_lightwhite"
                >
                  Log in
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    localStorage.removeItem("authtoken");
                    window.location.reload();
                  }}
                  className="login_btn d-sm-block d-none fs_17 fw-medium ff_heebo color_lightwhite"
                >
                  Log out
                </button>
              </>
            )}
            {/* <button
              onClick={() => setIsLoginPopup(true)}
              className="login_btn d-sm-block d-none fs_17 fw-medium ff_heebo color_lightwhite"
            >
              Log in
            </button> */}
            <button
              onClick={toggleFreeTrialModal}
              className="Free_btn border-0 d-sm-block d-none fs_17 fw-medium ff_heebo color_lightwhite"
            >
              Free Trial
            </button>
          </div>
          <div
            className={`navmanu d-sm-none position-relative custom_zindex d-block burger  ${
              sidebarVisible ? "active" : ""
            }`}
            onClick={toggleSidebar}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        {sidebarVisible && (
          <div
            className={`sidebar d-flex flex-column justify-content-center align-items-center  ${
              sidebarVisible ? "show " : "hide"
            }`}
          >
            <img
              onClick={toggleSidebar}
              className=" cursor_pointer position-absolute cross_icon d-sm-block d-none"
              width={30}
              src={cross_icon}
              alt="cross_icon"
            />
            <div className="d-block align-items-center gap-5 ">
              <ul className=" mb-0 ps-0 text-center">
                <Link to="/course">
                  <li>
                    <a
                      className=" fs_17 ff_heebo color_lightwhite fw-medium after_line d-inline position-relative"
                      href="#"
                    >
                      View Courses
                    </a>
                  </li>
                </Link>
                <Link to="/scholarshipPage-page">
                  <li className="mt-3">
                    <a
                      className=" fs_17 ff_heebo color_lightwhite fw-medium after_ "
                      href=""
                    >
                      Scholarship
                    </a>
                  </li>
                </Link>
                <Link to="/centers">
                  <li className="mt-3">
                    <a
                      className=" fs_17 ff_heebo color_lightwhite fw-medium after_line d-inline position-relative "
                      href=""
                    >
                      Centers
                    </a>
                  </li>
                </Link>
                <Link to="/quiz-page">
                  <li className="mt-3 overflow-hidden">
                    <a
                      className=" fs_17 ff_heebo color_lightwhite fw-medium after_ "
                      href=""
                    >
                      Quiz
                    </a>
                  </li>
                </Link>
                <button
                  onClick={toggleModal}
                  className="login_btn w-100 fs_17 mt-4 fw-medium ff_heebo color_lightwhite"
                >
                  Log in
                </button>
                <button className="Free_btn border-0 w-100 mt-3 fs_17 fw-medium ff_heebo color_lightwhite">
                  Free Trial
                </button>
              </ul>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={freeTrialModalVisible}
        centered
        onHide={toggleFreeTrialModal}
      >
        <div className="rounded-3 px-4 bg_darkblue position-relative pb-4">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFreeTrialModal}
              width={20}
              className="me-3 mt-3 cursor_pointer"
              src={crosswhtie_icon}
              alt="Close"
            />
          </div>
          <Modal.Header className="border-0 text-white pb-0 pt-5">
            <Modal.Title>
              <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-white">
                Free Trial Registration
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="" onSubmit={createForm}>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="py-2 px-3 mt-2"
                  placeholder="Enter Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Enter Your Mobile Number
                </label>
                <input
                  required
                  type="number"
                  className="py-2 px-3 mt-2"
                  placeholder="Enter Mobile Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Email
                </label>
                <input
                  required
                  type="email"
                  className="py-2 px-3 mt-2"
                  placeholder="Enter Email"
                  value={formData.email}
                  name="email"
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  City
                </label>
                <input
                  required
                  type="text"
                  className="py-2 px-3 mt-2"
                  placeholder="Enter City"
                  value={formData.topic}
                  name="topic"
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Nearest Center
                </label>
                <select
                  required
                  value={formData.nearestCenter}
                  name="nearestCenter"
                  onChange={handleInputChange}
                  className="py-2 px-3 mt-2"
                  id="nearestCenter"
                >
                  <option value=" -- Select Nearest Center --">
                    -- Select Nearest Center --
                  </option>
                  <option value="Hisar">Hisar</option>
                  <option value="Rohtak">Rohtak</option>
                  <option value="Sirsa">Sirsa</option>
                  <option value="Sikar">Sikar</option>
                </select>
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Qualification:
                </label>
                <select
                  required
                  value={formData.qualification}
                  name="qualification"
                  onChange={handleInputChange}
                  className="py-2 px-3 mt-2"
                  id=""
                >
                  <option value=" -- Select Qualification --">
                    -- Select Qualification --
                  </option>
                  <option value="10th">10th</option>
                  <option value="11th">11th</option>
                  <option value="12th">12th</option>
                  <option value="Graduation">Graduation</option>
                  <option value="Post Graduation">Post Graduation</option>
                </select>
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Which course are you looking for?
                </label>
                <select
                  required
                  value={formData.course}
                  name="course"
                  onChange={handleInputChange}
                  className="py-2 px-3 mt-2"
                  id=""
                >
                  <option value=" -- Select Course --">
                    -- Select Course --
                  </option>
                  <option value="Frontend Development">
                    Frontend Development
                  </option>
                  <option value="Backend Development">
                    Backend Development
                  </option>
                  <option value="FullStack Development">
                    FullStack Development
                  </option>
                  <option value="Web Development">Web Development</option>
                  <option value="Mobile App Development">
                    Mobile App Development
                  </option>
                </select>
              </div>
              <button
                type="submit"
                className="mt-5 bg_yellow ff_heebo fs_19 border-0 text-white w-100 py-2"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Request Callback"}
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Navbar;
