import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";

import HomePage from "./components/Pages/HomePage";
import CoursesDetailsPage from "./components/Pages/CoursesDetailsPage";
import CentersPage from "./components/Pages/CentersPage";
import ContactUsPage from "./components/Pages/ContactUsPage";
import Coursepage from "./components/Pages/Coursepage";
import { useCallback, useContext, useEffect, useState } from "react";
import HireFromUsPage from "./components/Pages/HireFromUsPage";
import AboutPage from "./components/Pages/AboutPage";
import ScholarshipPage from "./components/Pages/ScholarshipPage";
import BlogPage from "./components/Pages/BlogPage";
import WebinarsPage from "./components/Pages/WebinarsPage";
import QuizPage from "./components/Pages/QuizPage";
import PrivacyPolicyPage from "./components/Pages/PrivacyPolicyPage";
import TermsConditionPage from "./components/Pages/TermsConditionPage";
import RefundPolicyPage from "./components/Pages/RefundPolicyPage";
import LanguageModelPage from "./components/Pages/LanguageModelPage";
import RecordedCoursesPage from "./components/Pages/RecordedCoursesPage";
import SuccessStoriesPage from "./components/Pages/SuccessStoriesPage";
import SuccessStoriesDetailsPage from "./components/Pages/SuccessStoriesDetailsPage";
import AdminPanelPage from "./components/Pages/AdminPanelPage";
import Footer from "./components/common/Footer";
import AdminLoginPage from "./components/adminpanel/AdminLoginPage";
import { AppContext } from "./context/Context";
import CourseTab from "./components/adminpanel/CourseTab";
import BlogTab from "./components/adminpanel/BlogTab";
import AdminMainTemplate from "./Pages/Admin/AdminMainTemplate";
import TrainersList from "./components/adminpanel/TrainersList";
// import PreLoader from "./components/common/PreLoader";
import ContentTab from "./components/adminpanel/ContentTab";
import TestTab from "./components/adminpanel/TestTab";
import QuizTab from "./components/adminpanel/QuizTab";
import QuizTest from "./components/quizPage/QuizTest";
import LoginModal from "./components/common/LoginModal";
import crosswhtie_icon from "../src/assets/images/png/crosswhite_icon.png";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import makeApiCall from "./components/callApi";
import HisarCenter from "./components/centersPages/HisarCenter";
import SirsaCenter from "./components/centersPages/SirsaCenter";
import RohtakCenter from "./components/centersPages/RohtakCenter";
import JaipurCenter from "./components/centersPages/JaipurCenter";
import SikarCenter from "./components/centersPages/SikarCenter";
import UserTab from "./components/adminpanel/UserTab";
import LeadsTab from "./components/adminpanel/LeadsTab";
// import MasterClassHero from "./components/masterClassPage/MasterClassHero";
import MasterClassPage from "./components/Pages/MasterClassPage";
import ThankqPage from "./components/masterClassPage/ThankqPage";
import "react-toastify/dist/ReactToastify.css";
import PaymentStatusCheck from "./components/common/PaymentStatusCheck";
import CourseVideo from "./components/adminpanel/CourseVideo";
import PaymentStatusFiled from "./components/common/PaymentStatusFiled";

function App() {
  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  // const [adminLogin, setAdminLogin] = useState(false);

  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [value, setValue] = useState("");
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setformData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setformData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const createForm = async (e) => {
    e.preventDefault();

    const formdata = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
    };

    try {
      const res = await makeApiCall(
        formdata,
        "post",
        "new_lead",
        createFormCallBack,
        false
      );
      // createFormCallBack(res);
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
    }
  };

  const createFormCallBack = useCallback((res) => {
    if (res.data.status === "success") {
      toast.success(res.data.message);
      toggleFreeTrialModal();
      setformData({
        name: "",
        email: "",
        phone: "",
      });
    } else {
      toast.error(res.data.message);
    }
  });
  const { auth, setAuth, isLoginPopup, setIsLoginPopup } =
    useContext(AppContext);

  useEffect(() => {
    const adminToken = localStorage.getItem("admtoken");

    if (adminToken) {
      setAuth(true);
    }
  }, []);

  const location = useLocation();

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (loading) {
  //     document.body.classList.add("overflow-hidden");
  //   } else {
  //     document.body.classList.remove("overflow-hidden");
  //   }

  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 4000);

  //   return () => {
  //     document.body.classList.remove("overflow-hidden");
  //     clearTimeout(timer);
  //   };
  // }, [loading]);

  useEffect(() => {
    if (
      localStorage.getItem("authtoken") === undefined ||
      localStorage.getItem("authtoken") === null
    ) {
      const timer = setTimeout(() => {
        setIsLoginPopup(true);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [location, setIsLoginPopup]);

  return (
    <>
      <ToastContainer />
      {(location.pathname === "/scholarshipPage-page" ||
        location.pathname === "/" ||
        location.pathname.match(/^\/course-details\/[^/]+$/) ||
        location.pathname === "/quiz-page") &&
        isLoginPopup && <LoginModal />}
      {/* <div className=" position-relative overflow-hidden">
        {loading && <PreLoader />} */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/login" element={<LoginModal />} /> */}
        <Route path="/course-details/:id" element={<CoursesDetailsPage />} />
        <Route path="/centers" element={<CentersPage />} />
        <Route path="/contact-us-page" element={<ContactUsPage />} />
        <Route path="/course" element={<Coursepage />} />
        <Route path="/hire-from-us-page" element={<HireFromUsPage />} />
        <Route path="/about-page" element={<AboutPage />} />
        <Route path="/scholarshipPage-page" element={<ScholarshipPage />} />
        <Route path="/blog-page" element={<BlogPage />} />
        <Route path="/webinars-page" element={<WebinarsPage />} />
        <Route path="/master-class-page" element={<MasterClassPage />} />
        <Route path="/master-class-page/" element={<MasterClassPage />} />
        <Route path="/quiz-page" element={<QuizPage />} />
        <Route path="/thankq-page" element={<ThankqPage />} />
        <Route path="/privacypolicy-page" element={<PrivacyPolicyPage />} />
        <Route path="/terms-condition-page" element={<TermsConditionPage />} />
        <Route path="/refund-policy-page" element={<RefundPolicyPage />} />
        <Route path="/blog/:id" element={<LanguageModelPage />} />
        <Route path="/test/:type" element={<QuizTest />} />
        <Route path="/recorded-courses" element={<RecordedCoursesPage />} />
        <Route path="/success-stories-page" element={<SuccessStoriesPage />} />
        <Route path="/payment-success" element={<PaymentStatusCheck />} />
        <Route path="/payment-failed" element={<PaymentStatusFiled />} />
        <Route
          path="/success-stories-details-page"
          element={<SuccessStoriesDetailsPage />}
        />

        {/* -------------------Centers Pages------------------- */}
        <Route path="/hisar-center" element={<HisarCenter />} />
        <Route path="/sirsa-center" element={<SirsaCenter />} />
        <Route path="/rohtak-center" element={<RohtakCenter />} />
        <Route path="/jaipur-center" element={<JaipurCenter />} />
        <Route path="/sikar-center" element={<SikarCenter />} />

        {auth ? (
          <>
            {/* Admin routes */}
            <Route path="/admin2" element={<AdminPanelPage />} />
            <Route path="/admin" element={<AdminMainTemplate />}>
              <Route path="course-tab" element={<CourseTab />} />
              <Route path="blog-tab" element={<BlogTab />} />
              <Route path="trainers-list" element={<TrainersList />} />
              <Route path="content" element={<ContentTab />} />
              <Route path="test-tab" element={<TestTab />} />
              <Route path="quiz-tab" element={<QuizTab />} />
              <Route path="user-tab" element={<UserTab />} />
              <Route path="leads-tab" element={<LeadsTab />} />
              <Route path="course-video-tab" element={<CourseVideo />} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/admin" element={<AdminLoginPage />} />
          </>
        )}
      </Routes>

      {location.pathname !== "/admin" &&
        location.pathname !== "/admin2" &&
        location.pathname !== "/admin/course-tab" &&
        location.pathname !== "/admin/blog-tab" &&
        location.pathname !== "/admin/content" &&
        location.pathname !== "/admin/trainers-list" &&
        location.pathname !== "/admin/test-tab" &&
        location.pathname !== "/admin/quiz-tab" &&
        location.pathname !== "/admin/user-tab" &&
        location.pathname !== "/admin/leads-tab" &&
        location.pathname !== "/master-class-page" &&
        location.pathname !== "/admin/course-video-tab" &&
        location.pathname !== "/payment-status-check" &&
        location.pathname !== "/thankq-page" && <Footer />}

      {/* {location.pathname !== "/master-class-page" && (
        <a
          onClick={toggleFreeTrialModal}
          target="_blanck"
          href="#"
          className="d-sm-none d-block bg_yellow text-white position-fixed bottom-0 text-center w-100 border-0 p-3"
        >
          Registration Now
        </a>
        //   )}
        // </div>
      )} */}
      <Modal
        show={freeTrialModalVisible}
        centered
        onHide={toggleFreeTrialModal}
      >
        <div className="rounded-3 px-4 bg_darkblue position-relative pb-4">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFreeTrialModal}
              width={20}
              className="me-3 mt-3 cursor_pointer"
              src={crosswhtie_icon}
              alt="Close"
            />
          </div>
          <Modal.Header className="border-0 text-white pb-0 pt-5">
            <Modal.Title>
              <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-white">
                Free Trial Registration
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={createForm} action="">
              <div className="d-flex flex-column pt-0">
                <label
                  className=" fs_17 ff_heebo fw-medium text-white"
                  htmlFor=""
                >
                  Your Full Name
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="text"
                  placeholder="Enter Full Name"
                  value={formData?.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label
                  className=" fs_17 ff_heebo fw-medium text-white"
                  htmlFor=""
                >
                  Email
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData?.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label
                  className=" fs_17 ff_heebo fw-medium text-white"
                  htmlFor=""
                >
                  Contact Number
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="number"
                  placeholder="Enter contact number"
                  value={formData?.phone}
                  name="phone"
                  onChange={handleInputChange}
                />
              </div>
              <button
                type="submit"
                className="w-100 py-2 bg_yellow mt-5 text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
              >
                Submit
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default App;
