import React, { useState, useEffect } from "react";
import { FaClock, FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Import icons

const PaymentStatusFiled = ({ initialStatus }) => {
  return (
    <div
      style={{ textAlign: "center" }}
      className="d-flex flex-column justify-content-center min-vh-100"
    >
      <h3>Payment Status</h3>
      {/* {status === "Pending" ? ( */}
      {/* <div>
        <FaClock size={60} color="orange" />
        <p style={{ color: "orange", fontSize: "18px" }}>
          Your payment is pending...
        </p>
      </div> */}
      {/* ) : status === "Success" ? ( */}
      <div className="card col-md-4 p-5 mx-auto text-center  mt-4">
        <div className="d-flex justify-content-center mb-2">
          <FaTimesCircle size={60} color="red" />
        </div>
        <p style={{ color: "red", fontSize: "18px" }}>
          Your payment was Faild!
        </p>
      </div>
      {/* ) : ( */}
      {/* <div>
        <FaTimesCircle size={60} color="red" />
        <p style={{ color: "red", fontSize: "18px" }}>Your payment failed.</p>
      </div> */}
      {/* )} */}
    </div>
  );
};

export default PaymentStatusFiled;
