import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import teacherImage from "../../assets/images/png/Group 2507.png";
import playbutton from "../../assets/images/png/SVGRepo_iconCarrier.png";
import { Link } from "react-router-dom";
import makeApiCall from "../callApi";
const AboutComponent = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    makeApiCall("", "get", "courses", homeDataCallback);
  }, []);

  const homeDataCallback = (response) => {
    if (response.status === 200) {
      setCourses(response.data.data);
    } else {
      console.error("Error fetching courses");
    }
  };

  const convertDate = (date) => {
    const res = new Date(date).toDateString();
    const res2 = new Date(date).toLocaleTimeString();
    return { date: res, time: res2 };
  };

  return (
    <div className="py-5 curriculum_bg">
      <div className="container pb-4 mb-4">
        <div>
          <h1 className="ff_heebo fs_26 mb-0 fw-bold">Recorded Courses</h1>
        </div>

        <div className="d-none d-sm-block">
          {courses?.length === 0 ? (
            <p className="fs_15 ff_heebo fw-medium py-5 text-center">
              No data found.
            </p>
          ) : (
            <div className="row">
              {courses
                .filter((course) => course.type === "Offline")
                .map((course, i) => {
                  const convertedDate = convertDate(course?.createdAt);
                  return (
                    <div key={i} className="col-xl-4 col-md-6 mt-4 col-12">
                      <div className="centers_card h-100 d-flex flex-column justify-content-between bg_lightblue p-4 rounded-2">
                        <div>
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="d-flex align-items-center gap-2">
                              <button className="bg_darkblue px-2 py-1 rounded-1 fs_12 ff_heebo text-white border-0 fw-bold">
                                {course?.type}
                              </button>
                            </div>
                          </div>

                          <div className="d-flex flex-column py-3 gap-3">
                            <div>
                              <h1
                                style={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  WebkitLineClamp: 1,
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                                title={course?.title}
                                className="ff_heebo fs_24 fw-bold course_title"
                              >
                                {course?.title}
                              </h1>
                              <span className="ff_heebo">
                                Schedule: {course?.timing}
                                {/* {convertedDate?.time} */}
                              </span>
                            </div>
                            <div>
                              <div className="bg_darkblue rounded p-3 d-flex gap-2">
                                <div>
                                  <img
                                    width={70}
                                    src={`https://api.koduapp.com/public/images/${course?.trainer_id?.image}`}
                                    alt="hello"
                                  />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="text-white ff_heebo fs_12">
                                    Instructors
                                  </span>
                                  <span className="color_yellow ff_heebo fs_17 fw-bolder">
                                    {course?.trainer_id?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ height: "200px" }}
                              className="row overflow-y-auto mt-4"
                            >
                              {course?.features?.map((item, index) => {
                                return (
                                  <div className="col-md-6  gap-2" key={index}>
                                    <div className="d-flex gap-2">
                                      <div>
                                        <img src={playbutton} />
                                      </div>
                                      <div>
                                        <span className="fs_12 ff_heebo ">
                                          {item}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex w-100 justify-content-between align-items-center mt-2">
                            <div className="d-flex flex-column">
                              <span className="fs_15  ff_heebo gothrow_price">
                                ₹
                                {course?.discount === null
                                  ? "0"
                                  : course?.discount}
                              </span>
                              <span className="fs_29 fw-bolder color_blue ff_heebo">
                                ₹{course?.price}
                              </span>
                              <span className="fs_15 ff_heebo">
                                {course?.validity} Months
                              </span>
                            </div>
                            <div>
                              <Link to={`/course-details/${course._id}`}>
                                <button className="btn bg_darkblue ff_heebo text-white px-5 py-2">
                                  Enroll
                                </button>
                              </Link>
                            </div>
                          </div>
                          {/* <div>
                            <button className="Free_btn border-0 fs_14 fw-medium ff_heebo color_lightwhite my-3 w-100">
                              Get Counseling
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div className="d-sm-none courses">
          {" "}
          {/* Carousel for screens < 576px */}
          <Carousel showThumbs={false} autoPlay infiniteLoop>
            {courses
              .filter((course) => course.type === "Offline")
              .map((course, i) => {
                const convertedDate = convertDate(course.createdAt);
                return (
                  <div
                    key={i}
                    className="centers_card text-start h-100 d-flex flex-column justify-content-between bg_lightblue p-4 rounded-2"
                  >
                    <div>
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <button className="bg_darkblue px-2 py-1 rounded-1 fs_12 ff_heebo text-white border-0 fw-bold">
                            {course.type}
                          </button>
                        </div>
                      </div>

                      <div className="d-flex flex-column py-3 gap-3">
                        <div>
                          <h1
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 1,
                              cursor: "pointer",
                              position: "relative",
                            }}
                            title={course.title}
                            className="ff_heebo text-start fs_24 fw-bold course_title"
                          >
                            {course.title}
                          </h1>
                          <span className="ff_heebo text-start">
                            Schedule: {convertedDate?.date}{" "}
                            {convertedDate?.time}
                          </span>
                        </div>
                        <div>
                          <div className="bg_darkblue rounded p-3 d-flex gap-2">
                            <img
                              src={teacherImage}
                              width={50}
                              height={50}
                              className="rounded"
                            />
                            <div className="d-flex flex-column text-start">
                              <span className="text-white ff_heebo fs_12">
                                Instructors
                              </span>
                              <span className="color_yellow ff_heebo fs_17 fw-bolder">
                                Aman Gupta
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {course?.features?.map((item, index) => {
                            return (
                              <div className="col-md-6  gap-2" key={index}>
                                <div className="d-flex gap-2">
                                  <div>
                                    <img src={playbutton} />
                                  </div>
                                  <div>
                                    <span className="fs_12 ff_heebo ">
                                      {item}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex w-100 justify-content-between align-items-center mt-2">
                        <div className="d-flex flex-column">
                          <span className="fs_15 ff_heebo gothrow_price">
                            ₹{course.discount}
                          </span>
                          <span className="fs_29 fw-bolder color_blue ff_heebo">
                            ₹{course.price}
                          </span>
                          <span className="fs_15 ff_heebo">
                            {course.validity}
                          </span>
                        </div>
                        <Link to={`/course-details/${course._id}`}>
                          <button className="btn bg_darkblue ff_heebo text-white px-5 py-2">
                            Enroll
                          </button>
                        </Link>
                      </div>
                      <button className="Free_btn border-0 fs_14 fw-medium ff_heebo color_lightwhite my-3 w-100">
                        Get Counseling
                      </button>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
