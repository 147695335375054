import React, { useCallback, useState } from "react";
import sms_icon from "../../assets/images/png/sms_icon.png";
import phone_icon from "../../assets/images/png/phone_icon.png";
import image from "../../assets/images/png/image.png";
import img2 from "../../assets/images/png/img2.png";
import img3 from "../../assets/images/png/img3.png";
import img1 from "../../assets/images/png/img1.png";
import makeApiCall from "../callApi";
import { ToastContainer, toast } from "react-toastify";

const ContactUsHero = () => {
  const [formModal, setFormModal] = useState(false);
  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [value, setValue] = useState("");
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setformData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setformData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const createForm = async (e) => {
    e.preventDefault();

    const formdata = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
    };

    try {
      const res = await makeApiCall(
        formdata,
        "post",
        "new_lead",
        createFormCallBack,
        false
      );
      // createFormCallBack(res);
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
    }
  };

  const createFormCallBack = useCallback((res) => {
    console.log(res);
    if (res.data.status === "success") {
      toast.success(res.data.message);
      setformData({
        name: "",
        email: "",
        phone: "",
      });
    } else {
      toast.error(res.data.message);
    }
  });
  return (
    <div className="py-5">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h3 className="color_yellow fw-bold fs_43 ff_heebo">
              <span className=" color_blue">Let's connect.</span> We are just a
              click away!
            </h3>
            <div className="row  ">
              <div className="col-md-6 ps-5 mt-4">
                <p className=" text-center fw-bold text-black fs_16 mb-1">
                  Hire Students
                </p>
                <div className="bg_darkblue position-relative p-3 py-3 ps-5 rounded-2">
                  <img
                    width={80}
                    className="image_image"
                    src={img2}
                    alt="hello"
                  />
                  <h5 className=" color_yellow fs_20 fw-miduem ff_heebo  ps-xl-3">
                    Komal Sharma
                  </h5>
                  <div className="d-flex align-items-center gap-2 ps-lg-3">
                    <img src={sms_icon} alt="hello" />
                    <p className="mb-0 fs_15 ff_heebo text-white">
                      support@koduapp.com
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-2 ps-lg-3">
                    <img src={phone_icon} alt="hello" />
                    <p className="mb-0 fs_15 ff_heebo text-white">
                      08950214425
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 ps-5 mt-4">
                <p className=" text-center fw-bold text-black fs_16 mb-1">
                  Hire Students
                </p>
                <div className="bg_darkblue position-relative p-3 py-3 ps-5 rounded-2">
                  <img
                    width={80}
                    className="image_image"
                    src={img1}
                    alt="hello"
                  />
                  <h5 className=" color_yellow fs_20 fw-miduem ff_heebo  ps-xl-3">
                    Muskan
                  </h5>
                  <div className="d-flex align-items-center gap-2 ps-lg-3">
                    <img src={sms_icon} alt="hello" />
                    <p className="mb-0 fs_15 ff_heebo text-white">
                      koduelearning@gmail.com
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-2 ps-lg-3">
                    <img src={phone_icon} alt="hello" />
                    <p className="mb-0 fs_15 ff_heebo text-white">9138256355</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-6 ps-5 mt-4">
                <p className=" text-center fw-bold text-black fs_16 mb-1">
                  Hire Students
                </p>
                <div className="bg_darkblue position-relative p-3 py-3 ps-5 rounded-2">
                  <img
                    width={80}
                    className="image_image"
                    src={image}
                    alt="hello"
                  />
                  <h5 className=" color_yellow fs_20 fw-miduem ff_heebo  ps-md-3">
                    Kiran
                  </h5>
                  <div className="d-flex align-items-center gap-2 ps-lg-3">
                    <img src={sms_icon} alt="hello" />
                    <p className="mb-0 fs_15 ff_heebo text-white">
                      Jaipurkodu@gmail.com
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-2 ps-lg-3">
                    <img src={phone_icon} alt="hello" />
                    <p className="mb-0 fs_15 ff_heebo text-white">
                      073399-97234
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 ps-5 mt-4">
                <p className=" text-center fw-bold text-black fs_16 mb-1">
                  Hire Students
                </p>
                <div className="bg_darkblue position-relative p-3 py-3 ps-5 rounded-2">
                  <img
                    width={80}
                    className="image_image"
                    src={img3}
                    alt="hello"
                  />
                  <h5 className=" color_yellow fs_20 fw-miduem ff_heebo  ps-xl-3">
                    Varsha Bilkhiwal
                  </h5>
                  <div className="d-flex align-items-center gap-2 ps-lg-3">
                    <img src={sms_icon} alt="hello" />
                    <p className="mb-0 fs_15 ff_heebo text-white">
                      varsha.kodu@gmail.com
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-2 ps-lg-3">
                    <img src={phone_icon} alt="hello" />
                    <p className="mb-0 fs_15 ff_heebo text-white">8619971282</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="slider_box my-4 p-4 border-2 rounded-2">
              <form action="" onSubmit={createForm}>
                <div className="d-flex flex-column mt-4">
                  <label className="fs_16 ff_heebo fw-semibold" htmlFor="">
                    Your Full Name
                  </label>
                  <input
                    className=" bg-white w-100 mt-1 py-2 px-3 rounded-2"
                    type="text"
                    placeholder="Your Full Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="fs_16 ff_heebo fw-semibold" htmlFor="">
                    Email
                  </label>
                  <input
                    className=" bg-white w-100 mt-1 py-2 px-3 rounded-2"
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column mt-4">
                  <label className="fs_16 ff_heebo fw-semibold" htmlFor="">
                    Contact Number
                  </label>
                  <input
                    className=" bg-white w-100 mt-1 py-2 px-3 rounded-2"
                    type="number"
                    placeholder="Enter contact number"
                    value={formData.phone}
                    name="phone"
                    onChange={handleInputChange}
                  />
                </div>

                <button
                  type="submit"
                  className="w-100 py-2 bg_yellow border-0 mt-4 rounded-1 text-white fw-semibold fs_17 ff_heebo"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsHero;
